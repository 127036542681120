/**
 * @File: 侧边栏导航sliderMenu
 */
import * as types from '@/constants/sliderMenu'

export const saveSliderMenuCollapsed = (isCollapsed) => {
  return {
    type: types.SAVE_SLIDER_MENU_COLLAPSED,
    isCollapsed,
  }
}

export const saveAllowedMenus = (allowedMenus) => {
  return {
    type: types.SAVE_ALLOWED_MENUS,
    allowedMenus,
  }
}
