/**
 * @File: 路由匹配组件
 */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Layout } from 'antd'
import * as dimensions from '@/data/dimensions'
import {
  home,
  menuTemplate,
  productRegistration,
  functionRegistration,
  interfaceList,
  lessee,
  lesseeDetail,
  createdLessee,
  admin,
  adminDetail,
  createdAdmin,
} from '@/data/routerPaths'
import {
  AsyncHomePage,
  AsyncLesseeManage,
  AsyncLesseeManageDetail,
  AsyncAdminManage,
  AsyncAdminManageDetail,
  AsyncMenuTemplate,
  AsyncProductRegistration,
  AsyncInterfaceList,
  AsyncFunctionRegistration,
} from '@/pages'
import SliderMenu from './SliderMenu'
import GlobalHeader from './GlobalHeader'
import TabsBar from './TabsBar'
import styles from './Layouts.module.css'

const { Content, Header } = Layout

class MainLayout extends PureComponent {
  render() {
    const { isCollapsed, token, dispatch } = this.props
    // 加以登录控制
    if (!token) {
      dispatch(push('/login'))
      return null
    }
    const mainOffsetLeft = isCollapsed ? dimensions.SLIDER_MENU_COLLAPSED_WIDTH : dimensions.SLIDER_MENU_WIDTH
    const layoutWidth = { marginLeft: mainOffsetLeft }
    return (
      <Layout className={styles.root}>
        <SliderMenu />
        <Layout style={layoutWidth} className={styles.main}>
          <Header className={styles.header}>
            <GlobalHeader />
            <TabsBar />
          </Header>
          <Content className={styles.content}>
            <Switch>
              <Redirect exact from="/" to={home.path} />
              <Route exact path={home.path} component={AsyncHomePage} />

              <Route exact path={admin.path} component={AsyncAdminManage} />
              <Route exact path={createdAdmin.path} component={AsyncAdminManageDetail} key="create" />
              <Route exact path={adminDetail.path} component={AsyncAdminManageDetail} key="detail" />

              <Route exact path={lessee.path} component={AsyncLesseeManage} />
              <Route exact path={createdLessee.path} component={AsyncLesseeManageDetail} key="create" />
              <Route exact path={lesseeDetail.path} component={AsyncLesseeManageDetail} key="detail" />

              <Route exact path={menuTemplate.path} component={AsyncMenuTemplate} />
              <Route exact path={productRegistration.path} component={AsyncProductRegistration} />
              <Route exact path={interfaceList.path} component={AsyncInterfaceList} />
              <Route exact path={functionRegistration.path} component={AsyncFunctionRegistration} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    )
  }
}
export default connect((state) => {
  const { isCollapsed } = state.sliderMenu
  const { token } = state.account
  return { isCollapsed, token }
})(MainLayout)
