/**
 * @File: 账号相关
 */
import * as types from '@/constants/account'

export const loginSuccess = (info) => {
  // 登录
  return {
    type: types.LOGIN_SUCCESS,
    info,
  }
}

export const logout = () => {
  // 退出登录
  return {
    type: types.LOGOUT,
  }
}
