/**
 * @File: 顶部标签栏常量
 */
export const ADD_TAB = 'ADD_TAB' // 保存访问过的tab

export const DEL_TAB = 'DEL_TAB' // 删除访问过的tab

export const INIT_TABS_BAR = 'INIT_TABS_BAR' // 初始化 tabsBar

export const RELOAD_CUR_TAB = 'RELOAD_CUR_TAB' // 刷新当前tab数据

export const BACK_TAB = 'BACK_TAB' // 返回之前的页面
