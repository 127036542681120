/**
 * @File: 个人信息
 */
import React, { Component } from 'react'
import { Row, Col, Button } from 'antd'
import { connect } from 'react-redux'
import { BiciDrawer, BiciInput } from 'bici-transformers'

class UserInfo extends Component {
  render() {
    const { visible, onClose, name, phoneNumber } = this.props
    const footer = (
      <div className="tacenter">
        <Button type="primary" onClick={onClose}>
          取消
        </Button>
      </div>
    )
    return (
      <BiciDrawer width="sm" visible={visible} onClose={onClose} title="个人信息" footer={footer}>
        <Row>
          <Col span={24}>
            <BiciInput className="mb8" disabled label="姓名" value={name} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <BiciInput label="账号（手机号）" disabled value={phoneNumber} />
          </Col>
        </Row>
      </BiciDrawer>
    )
  }
}

export default connect((state) => {
  const { name, phoneNumber } = state.account
  return {
    name,
    phoneNumber,
  }
})(UserInfo)
