/**
 * @File: 请求封装
 */
import React from 'react'
import axios from 'axios'
import { push } from 'connected-react-router'
import { Modal, Icon } from 'antd'
import { biciNotification } from 'bici-transformers'
import store from '@/store'
import { startLoading, stopLoading } from '@/actions/loading'
import { logout } from '@/actions/account'

const { dispatch, getState } = store

const instance = axios.create({
  // baseURL: '192.168.1.105:31071',
  timeout: 10000,
  maxContentLength: Math.pow(1024, 2),
})

console.log(instance)
// 拦截请求
instance.interceptors.request.use(
  (config) => {
    let { isSilent = false } = config
    if (!isSilent) {
      dispatch(startLoading())
    }
    const token = getState().account.token
    if (token) {
      config.headers = { token }
    }
    return config
  },
  (error) => {
    let {
      config: { isSilent = false },
    } = error
    if (!isSilent) {
      dispatch(stopLoading())
    }
    biciNotification.error({ message: '发送请求失败' })
    return Promise.reject(error)
  }
)
// 拦截响应
instance.interceptors.response.use(
  (response) => {
    const {
      config: { isSilent = false, isCatch = true },
    } = response
    if (!isSilent) {
      dispatch(stopLoading())
    }
    const { data } = response
    if (data.code === 1000) {
      // 数据成功响应
      return Promise.resolve(data.data)
    } else if (data.code === 6514) {
      // 登录失效
      Modal.destroyAll()
      Modal.confirm({
        title: '登录超时，或权限被修改！',
        icon: <Icon type="close-circle" style={{ color: 'red' }} />,
        onOk() {
          dispatch(logout())
          dispatch(push('/login'))
        },
      })
      return Promise.reject()
    } else {
      // 数据响应失败
      if (isCatch) {
        // 默认抛出后端错误提示
        biciNotification.error({ message: data.msg })
        return Promise.reject()
      } else {
        // 由前端处理错误提示
        return Promise.reject(data.msg)
      }
    }
  },
  (error) => {
    // status非2开头的错误
    let {
      config: { isSilent = false },
    } = error
    if (!isSilent) {
      dispatch(stopLoading())
    }
    biciNotification.error({ message: '服务器响应失败' })
    return Promise.reject(error)
  }
)

export default instance
