/**
 * @File: 面包屑数据
 */
import {
  home,
  product,
  menuTemplate,
  productRegistration,
  functionRegistration,
  interfaceList,
  account,
  lessee,
  lesseeDetail,
  createdLessee,
  admin,
  adminDetail,
  createdAdmin,
} from './routerPaths'

const globalHeaderData = {
  [home.path]: { name: home.name, isRoute: true, hideClose: true },
  [product.path]: { name: product.name },
  [menuTemplate.path]: { name: menuTemplate.name, isRoute: true },
  [productRegistration.path]: { name: productRegistration.name, isRoute: true },
  [functionRegistration.path]: { name: functionRegistration.name, isRoute: true },
  [interfaceList.path]: { name: interfaceList.name, isRoute: true },
  [account.path]: { name: account.name },
  [lessee.path]: { name: lessee.name, isRoute: true },
  [lesseeDetail.path]: { name: lesseeDetail.name, isRoute: true },
  [createdLessee.path]: { name: createdLessee.name, isRoute: true },
  [admin.path]: { name: admin.name, isRoute: true },
  [adminDetail.path]: { name: adminDetail.name, isRoute: true },
  [createdAdmin.path]: { name: createdAdmin.name, isRoute: true },
}

export default globalHeaderData
