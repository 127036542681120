const initialStore = {
  // 全局菜单
  sliderMenu: {
    isCollapsed: false, // 侧边导航菜单栏是否折叠
    currentKey: '', // 当前活动的菜单
    allowedMenus: [], // 允许访问的目录
  },
  // 全局loading遮罩层
  loading: {
    loadingCount: 0,
  },
  // 标签管理
  tabsBar: {
    visitedTabs: [], // 访问过的tab
    tabIndex: 0,
  },
  // 账户
  account: {
    name: '',
    token: '',
    phoneNumber: '',
  },
}

export default initialStore
