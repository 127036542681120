/**
 * @File: 表单工具函数
 */
// 校验表单
export function verifyForm(refs) {
  let isError = false
  for (let key in refs) {
    if (refs[key] && refs[key].hint()) {
      isError = true
    }
  }
  return isError
}
// 清除表单错误提示
export function clearFormHint(refs) {
  for (let key in refs) {
    refs[key] && refs[key].clearHint()
  }
}
