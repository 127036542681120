/**
 * @File: 入口文件
 */
import 'antd/dist/antd.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './assets/css/basic.css'
import './assets/css/property.css'
import './assets/css/resetNormalize.css'
import './assets/css/card.css'
import './assets/css/tree.css'


// if (process.env.NODE_ENV === 'development') {
//   require('./mock')
// }

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
