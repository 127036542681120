/**
 * @File: 修改密码
 */
import React, { Component } from 'react'
import { Row, Col, Button } from 'antd'
import { BiciDrawer, BiciInput, BiciInputPassword } from 'bici-transformers'

class ModifyPassword extends Component {
  state = {
    originalPassword: '', // 初始密码
    newPassword: '', // 新密码
    repeatPassword: '', // 重复密码
  }
  // 表单refs
  formItemRefs = {}
  // 处理表单onchange
  handleFormOnChange = (stateFiled, val) => {
    this.setState({ [stateFiled]: val })
  }

  // 表单输入框置空
  handleCancelValue = () => {
    this.setState({
      originalPassword: '', // 初始密码
      newPassword: '', // 新密码
      repeatPassword: '', // 重复密码
    })
  }
  render() {
    const { visible, onClose, onOk } = this.props
    const { originalPassword, newPassword, repeatPassword } = this.state
    return (
      <BiciDrawer width="sm" visible={visible} onClose={onClose} onOk={onOk} title="修改密码" footer>
        <Row>
          <Col span={24}>
            <BiciInputPassword
              className="mb8"
              isRequired
              label="原密码"
              value={originalPassword}
              ref={(ref) => (this.formItemRefs.originalPasswordRef = ref)}
              onChange={(e) => {
                this.handleFormOnChange('originalPassword', e.target.value)
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <BiciInputPassword
              className="mb8"
              label="新密码"
              isRequired
              value={newPassword}
              ref={(ref) => (this.formItemRefs.newPasswordRef = ref)}
              onChange={(e) => {
                this.handleFormOnChange('newPassword', e.target.value)
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <BiciInputPassword
              label="重复新密码"
              isRequired
              value={repeatPassword}
              ref={(ref) => (this.formItemRefs.repeatPasswordRef = ref)}
              onChange={(e) => {
                this.handleFormOnChange('repeatPassword', e.target.value)
              }}
            />
          </Col>
        </Row>
      </BiciDrawer>
    )
  }
}

export default ModifyPassword
