import initialStore from '@/constants/initialStore'
import * as types from '@/constants/account'

export default (state = initialStore.account, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      const { name, token, phoneNumber } = action.info
      return {
        ...state,
        name,
        token,
        phoneNumber,
      }

    case types.LOGOUT:
      return {
        ...state,
        name: '',
        token: '',
        phoneNumber: '',
      }
    default:
      return state
  }
}
