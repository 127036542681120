/**
 * @File: 路径文件
 */
export const home = { name: '首页', path: '/home' }

export const product = { name: '产品配置', path: '/product' }

export const menuTemplate = { name: 'SaaS菜单模板', path: '/product/menuTemplate' }

export const productRegistration = { name: 'SaaS服务注册', path: '/product/productRegistration' }

export const functionRegistration = { name: '功能注册', path: '/product/productRegistration/functionRegistration' }

export const interfaceList = { name: 'SaaS接口清单', path: '/product/interfaceList' }

export const account = { name: '账号管理', path: '/account' }

export const lessee = { name: '租户账号管理', path: '/account/lessee' }

export const lesseeDetail = { name: '租户详情', path: '/account/lessee/:id' }

export const createdLessee = { name: '新建租户账号', path: '/account/lessee/create' }

export const admin = { name: '后台账号管理', path: '/account/admin' }

export const adminDetail = { name: '后台账号详情', path: '/account/admin/:id' }

export const createdAdmin = { name: '新建后台账号', path: '/account/admin/create' }
