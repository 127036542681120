/**
 * @File: 标签栏导航组件
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { BiciTabsBar } from 'bici-transformers'
import { initTabsBar, addTab, delTab } from '@/actions/tabsBar'

class TabsBar extends Component {
  // 处理路由跳转
  handleNav = (selectedTab) => {
    const { dispatch } = this.props
    const pathname = selectedTab.path
    const state = selectedTab.state
    dispatch(push({ pathname, state }))
    dispatch(addTab(pathname))
  }
  // 删除当前tab
  handleDelTab = (toJumpedIndex, resultTabs, toJumpedTab, deletedTab) => {
    const { dispatch, tabIndex, visitedTabs } = this.props
    const currentTab = visitedTabs[tabIndex]
    if (currentTab.path === deletedTab.path) {
      // 处理删除最后一个tab
      if (toJumpedTab) {
        const pathname = toJumpedTab.path
        const state = toJumpedTab.state
        dispatch(push({ pathname, state }))
      } else {
        dispatch(push('/'))
      }
    }
    dispatch(delTab(toJumpedIndex, resultTabs))
  }
  // 删除所有tab
  handleDelAllTabs = (toJumpedIndex, resultTabs) => {
    const { dispatch } = this.props
    dispatch(push('/'))
    dispatch(delTab(toJumpedIndex, resultTabs))
  }
  // 刷新当前tab
  handleReload = () => {
    const reloadEvent = this.reloadEvent
    // 触发事件
    window.dispatchEvent(reloadEvent)
  }
  componentDidMount() {
    const { dispatch, location } = this.props
    const currentPath = location.pathname
    const state = location.state || {}
    dispatch(initTabsBar(currentPath, state))
    // 注册刷新页面事件
    this.reloadEvent = new Event('reload')
  }
  render() {
    const { visitedTabs, tabIndex } = this.props
    if (visitedTabs.length <= 0) {
      return null
    }
    return (
      <BiciTabsBar
        tabs={visitedTabs}
        currentIndex={tabIndex}
        onSelectTab={this.handleNav}
        onReloadTab={this.handleReload}
        onCloseTab={this.handleDelTab}
        onCloseAllTabs={this.handleDelAllTabs}
      />
    )
  }
}

export default connect((state) => {
  const { visitedTabs, tabIndex } = state.tabsBar
  const { location } = state.router
  return {
    visitedTabs,
    tabIndex,
    location,
  }
})(TabsBar)
