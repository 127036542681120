/**
 * @File: 左侧菜单数据
 */
import { home, product, menuTemplate, productRegistration, interfaceList, account, lessee, admin } from './routerPaths'

const sliderMenuData = [
  {
    name: home.name,
    path: home.path,
    icon: 'dashboard',
  },
  {
    name: product.name,
    path: product.path,
    icon: 'project',
    children: [
      {
        name: menuTemplate.name,
        path: menuTemplate.path,
        icon: 'project',
      },
      {
        name: productRegistration.name,
        path: productRegistration.path,
        icon: 'project',
      },
      {
        name: interfaceList.name,
        path: interfaceList.path,
        icon: 'project',
      },
    ],
  },
  {
    name: account.name,
    path: account.path,
    icon: 'user',
    children: [
      {
        name: lessee.name,
        path: lessee.path,
        icon: 'user',
      },
      {
        name: admin.name,
        path: admin.path,
        icon: 'user',
      },
    ],
  },
]

export default sliderMenuData
