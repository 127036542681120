/**
 * @File: 根页面布局：包含header、sliderMenu 以及 路由匹配
 */
import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import MainLayout from './MainLayout'
import { AsyncAccountLogin } from '@/pages'
class RouterLayout extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/login" component={AsyncAccountLogin} />
        {/* 登录 */}
        <Route component={MainLayout} />
        {/* 应用主体区域布局及路由 */}
      </Switch>
    )
  }
}
export default RouterLayout
