/**
 * @File: 按需加载
 */
import Loadable from 'react-loadable'

const loading = () => null
const AsyncAccountLogin = Loadable({ loader: () => import('@/pages/Account/AccountLogin'), loading })
const AsyncHomePage = Loadable({ loader: () => import('@/pages/HomePage'), loading })
const AsyncAdminManage = Loadable({ loader: () => import('@/pages/AdminManage'), loading })
const AsyncAdminManageDetail = Loadable({ loader: () => import('@/pages/AdminManage/Details'), loading })
const AsyncFunctionRegistration = Loadable({ loader: () => import('@/pages/FunctionRegistration'), loading })
const AsyncInterfaceList = Loadable({ loader: () => import('@/pages/InterfaceList'), loading })
const AsyncLesseeManage = Loadable({ loader: () => import('@/pages/LesseeManage'), loading })
const AsyncLesseeManageDetail = Loadable({ loader: () => import('@/pages/LesseeManage/Details'), loading })
const AsyncMenuTemplate = Loadable({ loader: () => import('@/pages/MenuTemplate'), loading })
const AsyncProductRegistration = Loadable({ loader: () => import('@/pages/ProductRegistration'), loading })

export {
  AsyncHomePage,
  AsyncAccountLogin,
  AsyncAdminManage,
  AsyncAdminManageDetail,
  AsyncFunctionRegistration,
  AsyncInterfaceList,
  AsyncLesseeManage,
  AsyncLesseeManageDetail,
  AsyncMenuTemplate,
  AsyncProductRegistration,
}
