/**
 * @File: 顶部导航
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Icon, Menu, Dropdown } from 'antd'
import { biciNotification } from 'bici-transformers'
import { saveSliderMenuCollapsed } from '@/actions/sliderMenu'
import { logout } from '@/actions/account'
import { addTab } from '@/actions/tabsBar'
import globalHeaderData from '@/data/globalHeader'
import { fetchModifyPassword, fetchLogout } from '@/apis/account'
import { getEncryption, tranRSA } from '@/utils/encryptionUtil'
import BreadcrumbBar from './BiciBreadcrumbBar'
import UserInfo from './UserInfo' // 个人信息
import ModifyPassword from './ModifyPassword' // 修改密码
import { clearFormHint, verifyForm } from '@/utils/formUtil'
import styles from './GlobalHeader.module.css'

class GlobalHeader extends Component {
  state = {
    userInfoVisible: false,
    modifyPasswordVisible: false,
  }
  // 修改密码表单ref
  modifyPasswordRef = {}
  // 处理侧边栏开关
  handleSliderMenuTriggerToggle = () => {
    const { isCollapsed, dispatch } = this.props
    dispatch(saveSliderMenuCollapsed(!isCollapsed))
  }
  // 点击面包屑跳转tab
  handleNav = (path) => {
    const { dispatch } = this.props
    dispatch(addTab(path))
  }
  // 处理个人信息下拉菜单点击
  hanldeDropDownOnClick = ({ key }) => {
    const { dispatch } = this.props
    // 点击右上角的下拉面板
    this.setState({ [`${key}Visible`]: true }, () => {
      if (key === 'logout') {
        // 退出登录
        fetchLogout().then(() => {
          dispatch(logout())
          dispatch(push('/login'))
        })
      }
    })
  }
  // 点击取消个人信息弹窗
  handleCloseUserInfo = () => {
    this.setState({ userInfoVisible: false })
  }
  // 点击取消修改密码弹窗
  handleCloseModifyPassword = () => {
    clearFormHint(this.modifyPasswordRef.formItemRefs)
    this.modifyPasswordRef.handleCancelValue() // 输入框置空
    this.setState({ modifyPasswordVisible: false })
  }
  // 保存修改密码
  handleSaveModifyPassword = () => {
    // 校验表单是否通过验证
    if (verifyForm(this.modifyPasswordRef.formItemRefs)) {
      return
    }
    // 处理修改密码
    let { originalPassword, newPassword, repeatPassword } = this.modifyPasswordRef.state
    originalPassword = originalPassword.trim()
    newPassword = newPassword.trim()
    repeatPassword = repeatPassword.trim()
    const params = {
      originalPassword: getEncryption(tranRSA(originalPassword)),
      newPassword: getEncryption(tranRSA(newPassword)),
      repeatPassword: getEncryption(tranRSA(repeatPassword)),
    }
    fetchModifyPassword(params).then(() => {
      const { dispatch } = this.props
      biciNotification.success({ message: '密码修改成功' })
      fetchLogout().then(() => {
        // 修改密码成功 跳回登录
        dispatch(logout())
        dispatch(push('/login'))
      })
      this.setState({ modifyPasswordVisible: false })
    })
  }
  render() {
    const {
      isCollapsed,
      name,
      location: { pathname },
    } = this.props
    const { userInfoVisible, modifyPasswordVisible } = this.state
    const menu = (
      <Menu onClick={this.hanldeDropDownOnClick}>
        <Menu.Item key="userInfo">个人信息</Menu.Item>
        <Menu.Item key="modifyPassword">修改密码</Menu.Item>
        <Menu.Item key="logout">退出登录</Menu.Item>
      </Menu>
    )
    return (
      <div>
        <div className={styles.root}>
          <div className="dpflex flex1">
            <Icon
              type={isCollapsed ? 'menu-unfold' : 'menu-fold'}
              className={styles.trigger}
              onClick={this.handleSliderMenuTriggerToggle}
            />
            <BreadcrumbBar className={styles.breadcrumb} data={globalHeaderData} pathname={pathname} />
          </div>
          <div className={styles.userInfo}>
            <Dropdown overlay={menu}>
              <div>
                <Icon type="user" className="fs16" />
                <span className="ml12">{name}</span>
              </div>
            </Dropdown>
          </div>
        </div>
        {/* 用户详情抽屉 */}
        <UserInfo visible={userInfoVisible} onClose={this.handleCloseUserInfo} />
        {/* 编辑密码抽屉 */}
        <ModifyPassword
          ref={(ref) => (this.modifyPasswordRef = ref)}
          visible={modifyPasswordVisible}
          onClose={this.handleCloseModifyPassword}
          onOk={this.handleSaveModifyPassword}
        />
      </div>
    )
  }
}

export default connect((state) => {
  const { isCollapsed } = state.sliderMenu
  const { name } = state.account
  const { location } = state.router
  return { isCollapsed, name, location }
})(GlobalHeader)
