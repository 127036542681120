/**
 * @File: 账号相关请求： 登录/个人信息/修改密码
 */
import request from '@/utils/request'

export const fetchLogin = (params) => {
  return request.post('/api/management/login', params)
}

export const fetchLogout = (params) => {
  return request.post('/api/management/systemAdmin/logout', params)
}

export const fetchModifyPassword = (params) => {
  return request.post('/api/management/systemAdmin/updatePassword', params)
}
