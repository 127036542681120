/**
 * @File: 全局加载 loading
 */
import * as types from '@/constants/loading'

export function resetLoading() {
  return {
    type: types.RESET_LOADING,
  }
}

export function startLoading() {
  return {
    type: types.START_LOADING,
  }
}

export function stopLoading() {
  return {
    type: types.STOP_LOADING,
  }
}
