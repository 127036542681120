/**
 * @File: 根组件
 * @Docs: https://github.com/supasate/connected-react-router#usage
 */
import React, { Component } from 'react'
import { LocaleProvider } from 'antd'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { DragDropContextProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import store, { history, persistor } from './store'
import RouterLayout from './pages/RouterLayout'

class App extends Component {
  render() {
    return (
      <LocaleProvider locale={zhCN}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <DragDropContextProvider backend={HTML5Backend}>
                <RouterLayout />
              </DragDropContextProvider>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </LocaleProvider>
    )
  }
}

export default App
