/**
 * @File: store根文件
 */
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import createRootReducer from './reducers'
import initialStore from '@/constants/initialStore'

export const history = createBrowserHistory()

function configureStore(preloadedState) {
  const NODE_ENV = process.env.NODE_ENV
  const persistConfig = {
    // ReduxPersist 的持久化配置
    key: 'management-web',
    storage, // defaults to localStorage for web and AsyncStorage for react-native
    whitelist: ['account'],
  }
  const logger = createLogger({
    // 配置 redux-logger: https://github.com/LogRocket/redux-logger#options-description
    duration: true,
    collapsed: true,
  })
  // https://github.com/rt2zz/redux-persist#persistreducerconfig-reducer
  // 得到持久化了的并且联结了 ReactRouter 的 Reducer
  const persistedReducer = persistReducer(persistConfig, createRootReducer(history))
  let middlewares = [
    routerMiddleware(history), // for dispatching history actions
  ]
  let composeEnhancers = compose
  if (NODE_ENV === 'development') {
    // 做本地开发环境的配置区分
    middlewares.push(logger)
    composeEnhancers = composeWithDevTools
  }
  return createStore(persistedReducer, preloadedState, composeEnhancers(applyMiddleware(...middlewares)))
}

const store = configureStore(initialStore)
// https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
export const persistor = persistStore(store)

export default store
