/**
 * @File: 布局尺寸相关 例如：菜单导航宽高相关常量
 */
//导航侧边菜单
export const SLIDER_MENU_WIDTH = 208 // 导航宽度

export const SLIDER_MENU_COLLAPSED_WIDTH = 80 // 导航折叠状态宽度

export const COMPATIBLE_WIDTH = 1366 // 浏览器兼容最小档宽度

export const PAGER_MAIN_WIDTH = COMPATIBLE_WIDTH - SLIDER_MENU_WIDTH - 12 * 4 // 最小浏览器兼容宽度下，页面主区域的宽度，常用于设定表格的 minWidth

export const clientWidth = document.body.clientWidth

export const clientHeight = document.body.clientHeight
