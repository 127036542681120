/**
 * @File: 密钥处理工具函数
 */
import { JSEncrypt } from 'jsencrypt'

/* 先base64转码，然后URI编码 */
export function getEncryption(sourceStr) {
  return window.encodeURIComponent(window.btoa(sourceStr))
}

// RSA加密
export const tranRSA = (param) => {
  let jsencrypt = new JSEncrypt()
  jsencrypt.setPublicKey(
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvJGaiiS3oLK9QXgm1jpzKe3g4jKRu0zXWqjaazh9NW13vdMcu3ctKT2+GqV9I7FMBgP69p9LX1hOXoSmagYB5Qku1Vrjx03mjnhcYaCleJzv7vksb8Rsx/Dd8pRCVoYvjsgawYB+oxnvlHKvk7d/XuHCOY02Tod21KpsBQ6Z9AwIDAQAB'
  )
  return jsencrypt.encrypt(param)
}
