/**
 * @File: 全局加载loading
 */
import initialStore from '@/constants/initialStore'
import * as types from '@/constants/loading'

export default (state = initialStore.loading, action) => {
  switch (action.type) {
    case types.START_LOADING:
      return {
        ...state,
        loadingCount: state.loadingCount + 1,
      }
    case types.STOP_LOADING:
      return {
        ...state,
        loadingCount: state.loadingCount - 1,
      }
    case types.RESET_LOADING:
      return {
        ...state,
        loadingCount: 0,
      }
    default:
      return state
  }
}
