/**
 * @File: 侧边导航
 */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { Layout, Menu, Icon } from 'antd'
import sliderMenuData from '@/data/sliderMenu'
import * as dimensions from '@/data/dimensions'
import { fetchSliderMenu } from '@/apis/sliderMenu'
import { addTab, initTabsBar } from '@/actions/tabsBar'
import { saveAllowedMenus } from '@/actions/sliderMenu'
import styles from './SliderMenu.module.css'

const { Sider } = Layout
const SubMenu = Menu.SubMenu
const MenuItem = Menu.Item

class SliderMenu extends PureComponent {
  // 点击logo回到首页
  handleLogoClick = () => {
    const { dispatch } = this.props
    dispatch(push('/'))
  }
  // 跳转tab
  handleNav = (curPath) => {
    const { dispatch } = this.props
    dispatch(addTab(curPath))
  }
  async componentDidMount() {
    const { dispatch } = this.props
    const allowedMenus = await fetchSliderMenu()
    dispatch(saveAllowedMenus(allowedMenus))
  }
  renderMenu = () => {
    const { allowedMenus, currentKey } = this.props
    return (
      <Menu theme="dark" mode="inline" onClick={this.handleMenuClick} selectedKeys={[currentKey]}>
        {sliderMenuData.map((subMenu) => {
          if (allowedMenus.indexOf(subMenu.path) === -1) {
            return null
          }
          const title = (
            <span>
              <Icon type={subMenu.icon} />
              <span>{subMenu.name}</span>
            </span>
          )

          if (subMenu.children) {
            // 含有二级菜单
            return (
              <SubMenu key={subMenu.name} title={title}>
                {subMenu.children.map((item) => {
                  if (allowedMenus.indexOf(item.path) === -1) {
                    return null
                  }
                  return (
                    <MenuItem key={item.name}>
                      <Link onClick={() => this.handleNav(item.path)} to={item.path}>
                        <span>
                          <Icon type={item.icon} />
                          <span>{item.name}</span>
                        </span>
                      </Link>
                    </MenuItem>
                  )
                })}
              </SubMenu>
            )
          }

          // 仅一级菜单
          return (
            <MenuItem key={subMenu.name}>
              <Link onClick={() => this.handleNav(subMenu.path)} to={subMenu.path}>
                <span>
                  <Icon type={subMenu.icon} />
                  <span>{subMenu.name}</span>
                </span>
              </Link>
            </MenuItem>
          )
        })}
      </Menu>
    )
  }
  render() {
    const { isCollapsed } = this.props
    return (
      <Sider
        className={styles.root}
        width={dimensions.SLIDER_MENU_WIDTH}
        collapsible
        collapsed={isCollapsed}
        collapsedWidth={dimensions.SLIDER_MENU_COLLAPSED_WIDTH}
        trigger={null}
      >
        <div onClick={this.handleLogoClick} className={styles.logoWrapper}>
          <div className={styles.logo} />
          {!isCollapsed && <div>博智后台管理系统</div>}
        </div>
        {this.renderMenu()}
      </Sider>
    )
  }
}

export default connect((state) => {
  const { isCollapsed, allowedMenus } = state.sliderMenu
  const { visitedTabs, tabIndex } = state.tabsBar
  const currentTab = { ...visitedTabs[tabIndex] }
  const { location } = state.router
  const currentKey = currentTab.name || ''
  return { isCollapsed, currentKey, allowedMenus, location }
})(SliderMenu)
